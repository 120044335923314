<template>
  <v-container>
    <v-card rounded :disabled="showDialog">
      <v-card-title>{{getTitle}}</v-card-title>
      <v-card-subtitle>
        <br/>
        <p>{{getStart}}</p>
        <p>{{getTime}}</p>
      </v-card-subtitle>
      <v-card-text v-html="getText"/>
      <v-card-actions>
        <custom-dialog
            v-model="showDialog"
            :activator-label="$t('LBL_STOP_SESSION')"
            :dialog-title="$t('MSG_CONFIRM_STOP')"
            @activate="onSubmit"
            @cancel="onCancel"
            @confirm="onConfirm"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CustomDialog from '@/components/CustomDialog';
import {mapGetters} from 'vuex';
export default {
  name: "StopSession",
  components: {CustomDialog},
  data: () => ({
    showDialog: false,
    time: 3837,
    handler: null,
    session: {
      id: 'default',
      start: new Date().toISOString(),
      type: 'x',
      details: ''
    },
  }),
  computed: {
    ...mapGetters(['getCurrentSession']),
    getTitle() {
      return `${this.$t("LBL_TITLE", {session: this.session.id})}`;
    },
    getTime() {
      const hr = Math.trunc(this.time/3600);
      let remain = this.time - hr*3600;
      const min = Math.trunc(remain/60);
      remain = remain - min*60;
      const sec = remain%60;

      return `${this.$t("LBL_SUBTITLE")} ${this.$tc("TIME_HR", hr)} ${this.$tc("TIME_MIN", min)} ${this.$tc("TIME_SEC", sec)}`;
    },
    getText() {
      return `<p>${this.$t("LBL_WORK_TYPE", {type: this.getSessionTypeName(this.session.type)})}</p>`+
             `<p>${this.$t("LBL_WORK_DETAILS", {details: this.session.details})}</p>`
    },
    getStart() {
      const date = Date.parse(this.session.start);
      return `${this.$t('LBL_START_AT', {when: this.$d(date, 'long')})}`
    }
  },
  methods: {
    getSessionTypeName(type) {
      switch (type) {
        case "b": return this.$t("type.session.fix");
        case "f": return this.$t("type.session.feature");
        case "e": return this.$t("type.session.enhance");
        case "d": return this.$t("type.session.doc");
        case "r": return this.$t("type.session.research");
        case "o": return this.$t("type.session.other");
      }
    },
    onCancel() {
      this.showDialog = false;
    },
    async onConfirm() {
      const result = await this.$store.dispatch('stopSession');
      if (result) {
        await this.$router.replace("/choice");
      }
    },
    onSubmit() {
      this.showDialog = true;
    },
  },
  mounted() {
    this.session = this.getCurrentSession;
    const start = Date.parse(this.session.start);
    const now = new Date().getTime();

    this.time = Math.trunc((now - start)/1000);
    this.handler = setInterval(()=>this.time++, 1000);
  },
  beforeDestroy() {
    clearInterval(this.handler);
  }
}
</script>

<i18n>
{
  "en": {
    "TIME_HR": " | {n} Hour | {n} Hours",
    "TIME_MIN": " | {n} Minute | {n} Minutes",
    "TIME_SEC": "{n} Second | {n} Seconds",
    "LBL_TITLE": "Session Id: {session}",
    "LBL_SUBTITLE": "This session has been stared for:",
    "LBL_START_AT": "This session was started at: {when}",
    "LBL_WORK_TYPE": "Work Type: {type}",
    "LBL_WORK_DETAILS": "Details: {details}",
    "LBL_BTN_CANCEL": "Cancel",
    "LBL_BTN_CONFIRM": "Confirm",
    "LBL_STOP_SESSION": "Stop current session",
    "MSG_CONFIRM_STOP": "Confirm to stop current session?"
  }
}
</i18n>
